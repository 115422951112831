<template>
  <div class="bg" :style="{'backgroundImage':'url('+ Region +')'}">
    <div class="class--con">
      <class-but @forward="forward"/>
    </div>
  </div>
</template>

<script>
import ClassBut from "@/views/conference/ConferenceItem/classBut.vue";
  // import cs from "@/assets/image/conference/cs.jpg"
  // import gz from "@/assets/image/conference/gz.jpg"
  // import wh from "@/assets/image/conference/wh.jpg"
  import yh618 from "@/assets/image/conference/pic_618youhui.png"
import service from "@/utils/axios";
  export default {
    name: "nineteenPage",
    components: {ClassBut},
    data(){
      return{
        // cs:cs,
        // gz:gz,
        // wh:wh,
        yh618:yh618,
        Region:null,
      }
    },
    created() {
      this.gitApidoubleTTArea()
    },
    methods:{
      forward(){
        this.$router.push({
          name: 'PageTwentiethFirth',
          query: this.$route.query,
        })
      },
      async gitApidoubleTTArea(){
        let res = await service.post(`/dwart/an_teaching/hbk/v1/search/doubleTTArea`, {
          classId: this.$route.query.classId
        })
        // todo  武汉广州及长沙片区显示 其他片区跳过
        if ( res === 1 ) {
          this.Region = this.yh618
        }else if ( res === 2 ) {
          this.Region = this.yh618
        }else {
          await this.$router.replace({
            name: 'PageTwentiethFirth',
            query: this.$route.query,
          })
        }
      }
    }
  }
</script>

<style lang="less" scoped >
  @import "reportClass.less";

</style>
